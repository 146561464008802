nav{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 0;
    color: white;
    transition: all .2s ease;
    z-index: 2;
  }
  
  .nav-desktop{
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .coloredBackground{
    background-color: #da3349;
  }

  .hamburger-menu{
    display: none;
  }
  
  .hambruger-content-wrapper{
    height: 100dvh;
  }
  
  .contact-nav{
    background-color: #e09f3e;
    padding: .5rem 1.5rem;
    border-radius: 10px;
    transition: all .2s ease;
  }
  
  .contact-nav::after{
    background-color: transparent;
  }
  
  .contact-nav:hover{
    background-color: #f8c06b;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  header img{
    height: 120px;
  }
  
  ul{
    list-style: none;
  }
  
  ul a{
    color: white;
  }
  
  ul li{
    display: inline-block;
    margin-right: 2rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    padding: .25rem 0;
    font-size: 1.3rem;
  }
  
  ul li::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: rgb(255, 230, 230);
    transition: opacity 0.5s ease;
    opacity: 0;
  }
  
  ul li:hover::after{
    width: 100%;
    opacity: 1;
  }

  ul li:hover ul.dropdown{
    display: block;
  }

  ul li a{
    text-decoration: none;
    color: white;
  }

  .dropdown{
    position: absolute;
    width: 300px;
    z-index: 999;
    display: none;
    background-color: #da3349;
    border-radius: 0 0 10px 10px;
  }

  .dropdown li::after{
    content: none;
  }

  .dropdown li{
    width: 100%;
    padding: 0.5rem 1rem;
    transition: all .2s ease;
    border-bottom: 2px solid #b6273a;
  }

  .dropdown li:last-child{
    border-bottom: none;
  }

  .dropdown li:hover{
    background-color: #c92b40;
  }

  .dropdown li a{
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .sticky-nav{
    margin-top: 0;
    width: 100%;
    border-radius: 0;
    z-index: 99;
    background-color: #da3349;
  }

  .cta .cta-text{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: none;
    color: white;
  }

  .exit-icon{
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 999;
    height: 25px;
    width: 25px;
  }

