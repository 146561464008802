.service-page{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .services-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */
  .sub-heading{
    padding-inline: 0.75rem;
  }
  
  .sub-heading h1{
    font-size: 2rem;
  }

  .sub-heading p{
    font-size: 1rem;
  }
  
  .sub-heading span{
    color: #da3349;
  }
  
  .sub-heading img{
    height: 600px;
  }

  .services-list{
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    padding-top:3rem;
  }

  .service-item{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(235, 235, 235);
    overflow: hidden;
    border-radius: 10px;
    padding: 1rem;
    width: 250px;
    height: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .service-item:hover .service-icon{
    color: black;
    transform: rotate(-15deg);
  }

  .service-item button{
    padding: .5rem 1rem;
    font-size: .8rem;
    border-radius: 5px;
  }

  .service-item a{
    align-self: flex-start;
  }

  .icon-container{
    border-radius: 100%;
    padding: 1rem;
    width: fit-content;
  }

  .service-icon{
    width: 200px;
    position: absolute;
    bottom: 0;
    right: -80px;
    transform: scaleX(-1);
    color: rgb(187, 187, 187);
    z-index: 0;
    height: fit-content;
    transition: all .5s;
  }

  .service-icon:hover{
    transform: rotate(15px);
    color: black;
  }

  .service-text p{
    margin-top: 1rem;
  }

  @media screen and (max-width:1380px) {
    .services-content{
        flex-direction: column;
      }
    
      .services-content .sub-heading img{
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -o-object-fit: contain;
           object-fit: contain;
      }
    
      .services-list{
        width: 90%;
        justify-content: center;
      }
    
      .service-item{
        flex-direction: column;
      }
    
      .service-item .service-text h2{
        text-align: center;
      }
  }