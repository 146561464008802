.overlay-video{
    display: none;
}

.landing-hero{
    padding-top: 156px;
    height: 100vh;
    display: flex;
    align-items: center;
    gap: 2rem;
}
.card{
    height: 100%;
    border-radius: 10px;
    padding: 6rem 4rem 6rem 4rem;
    background-color: rgb(242, 243, 243);
}

.column{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 47%;
    max-height: 75vh;
}

.column .column-sub{
    margin-top: 2rem;
}

.column .column-sub h1{
    line-height: 4rem;
}


.column span{
    color:#da3349;
}

.column .column-sub p{
    margin-top: 1.5rem;
    font-size: 1.5rem;
}

.button-group{
    align-items: center;
    align-self: self-start;
    width: 100%;
}

.column video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    filter: brightness(0.6);
}

.video-column{
    padding: 0;
    height: 100%;
}

.landing-hero{
    display: flex;
    justify-content: space-between;
}

.list-container{
    width: 100%;
    margin: 6rem 0;
    padding-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.painting-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 75%;
    gap: 1rem;
    margin-top: 2rem;
}

.item-card{
    width: 40%;
    background-color: rgb(231, 231, 231);
    padding: 4rem 2rem;
    min-height: 100%;
    flex: 1 1 auto;
}

.item-card h1{
    line-height: 3rem;
}

.item-card h2{
    line-height: 3rem;
}

.color-consultation{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(80% - 4rem);
    margin-top: 2rem;
    padding: 2rem;
    border-radius: 10px;

}

.faq{
    display: flex;
    flex-direction: column;
    padding-bottom: 6rem;
    margin-bottom: 6rem;
}

.questions-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 0rem 4rem ;
    width: 100%;
}

.questions-container{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    flex: 1 1 40%;
}

.questions-title{
    display: flex;
    align-items: center;
    gap: 0.2rem;
    background-color: rgb(228, 228, 228);
    border-radius: 10px;
    padding: 1rem 2rem;
    transition:  all .2s;
}

.accordion:hover{
    cursor: pointer;
    background-color: rgb(223, 223, 223);

}

.panel{
    display: none;
    transition: all .2s;
}

.questions-list{
    padding-left: 1rem;
}

.question-item{
    margin-top: 1rem;
}

.question{
    font-weight: bold;
}

.section-title{
    font-size: 4rem;
}

.section-subtitle{
    font-size: 2rem;
    line-height: 2.5rem;
}

.section-subtitle span{
    background-color: #da3349;
    padding: .6rem 1rem;
    color: white;
    border-radius: 10px;
}

@media screen and (max-width:1820px) {
    .item-card h2{
        line-height: 1.5rem;
    }
}
@media screen and (max-width:1380px) {
    .video-column{
        display: none;
    }

    .overlay-video{
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        top: 0;
        z-index: 0;
        opacity: 0.5;
    }

    .video-desktop{
        display: none;
    }

    .column{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .column-sub, .button-group{
        z-index: 2;
    }

    .page{
        padding: 2rem 0 4rem 0;
        width: 100%;
    }

    .section-subtitle{
        padding: 4rem 0 ;
        text-align: center;
    }

    .card{
        padding: 2rem;
    }

    .card:nth-child(2){
        padding: 0;
    }

    .landing-hero{
        padding: 2rem ;
        align-items: center;
        gap: 0;
        height: 100vh;
    }

    .section-title{
        font-size: 3rem;
    }

    .column .column-sub h1{
        line-height: 3rem;
    }

    .column .column-sub p{
        font-size: 1rem;
    }
}


@media screen and (max-width:768px) {
    .landing-hero{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .column{
        width: 100%;
    }

    .column .column-sub{
        margin-top: 0;
    }

    .video-column{
        display: none;
    }

    .painting-list{
        width: 80%;
    }

    .item-card{
        width: 50%;
    }

    .color-consultation{
        width: 100% ;
    }
}
@media screen and (max-width:426px) {
    .section-title{
        font-size: 2.5rem;
    }

    .column .column-sub h1{
        line-height: 2.5rem;
    }

    .column .column-sub p{
        margin-top: 1rem;
    }
}

@media screen and (max-width:320px) {
    .landing-hero{
        padding: 1rem;
    }
}