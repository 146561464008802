.review-page{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-block : 6rem;
  }

  .widget-container{
    display: flex;
    padding-top: 5rem;
  }