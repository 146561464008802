@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Montserrat', sans-serif;
  height: 100%;
}

button{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 2rem;
  border: none;
  background-color: #e09f3e;
  border-radius: 10px;
  color: white;
  font-size: 1rem;
  margin-top: 2rem;
  cursor: pointer;
  transition: all .2s ease;
  font-weight: bold;
}

button:hover{
  background-color: #f8c06b;
}

.page{
  position: relative;
  width: 70%;
  margin: auto;
  padding: 6rem 2rem;
}

.background-wrapper > :not(:first-child):not(:nth-child(2)):not(:nth-child(4)):not(:nth-child(8)){
  /* padding: 3rem 0; */
}

.service-page, .hero-page{
  min-height: calc(100dvh - 2rem);
}

.page::after{
  content: '';
  height: 2px;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  background-color: rgb(241, 241, 241);
}

.page:last-of-type::after{
  content: none
};

/* Navbar Styling */


@media screen and (max-width:1380px)  {

  .page .sub-heading h1{
    font-size: 2rem;
  }

  .page .sub-heading p{
    font-size: 1rem;
  }
}

@media screen and (max-width:1070px) {


  .footer-content{
    flex-direction: column;
  }
}