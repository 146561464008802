footer{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: auto;
    padding: 3rem 0;
    background-color: #474747;
    color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  .footer-content{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  
  .footer-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .footer-info{
    display: flex;
  }

  .footer-info-mb{
    display: none;
  }

  .footer-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    flex: 1;
  }
  
  .footer-list li{
    display: flex;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
  }
  
  .socials li{
    display: inline-block;
  }
  
  .socials ul li svg{
    max-width: 50px;
    max-height: 50px;
  }
  
  .socials a{
    color: white;
  }
  
  .footer-icon{
    position: absolute;
    right: 0;
    bottom: -80px;
    color: #6b6a6a;
    z-index: 0;
  }
  
  .phone-contact{
    line-height: 1.5rem;
  }
  
  .phone-contact span{
    font-weight: bold;
    color: white;
  }
  
  .phone-contact a:hover{
    text-decoration: underline;
  }
  
  .phone-contact a{
    text-decoration: none;
  }
  
  .copyright{
    width: 83%;
    margin: auto;
    color: #a7a7a7;
  }

  .socials-wrapper a svg{
    display: none;
  }

  @media screen and (max-width:1380px) {
    ul li{
      margin-right: 0;
    }
    footer{
        gap: 2rem 0;
        width: 100%;
        border-radius: 0;
      }

      .footer-section{
        width: 100%;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
      }

      .footer-list{
        display: flex;
        flex-direction: column;
      }

      .footer-socials-desktop{
        display: none;
      }

      .footer-sitemap-desktop{
        display: none;
      }
    
      .footer-content{
        align-items: flex-start;
        gap: 2rem;
      }

      .footer-info-mb{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        width: 100%;
      }

      .footer-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    
      .footer-icon{
        display: none;
      }
    
      .copyright{
        text-align: center;
      }

      .footer-list .footer-heading{
        font-weight: bold;
      }

      .footer-list li svg{
        max-width: 50px;
        max-height: 50px;
      }

      .socials-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
      }

      .socials-wrapper a svg{
        display: block;
        max-width: 40px;
        max-height: 40px;
        color: white;
      }
  }

  @media screen and (max-width:426px) {
    .footer-info-mb{
      flex-direction: column;
    }
  }