
.home-page:nth-child(-1){
  background-color: red;
}

.background-image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .25;
    z-index: 0;
  }
  
  .hero-page{
    width: 100%;
    position: relative;
    padding: 0;
  }

  .hero-page::after{
    height: 0;
  }
  
  .hero-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    z-index: 4;
    width: 100%;
    height: 100dvh;
  }
  
  .content{
    display: flex;
    height: calc(100dvh - 136px);
    align-items: center;
    justify-content: space-around;
  }
.logo-hero-mb{
  display: none;
}
  .hero-page .content{
    display: flex;
    align-items: center;
    margin: auto;
    width: 50%;
    justify-content: center;
  }
  
  .hero-page .heading{
    text-align: center;
  }
  
  .text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100dvh;
    text-align: center;
    z-index: 2;
    line-height: 2rem;
  }
  
  .text-container .heading{
    font-size: 4.5rem;
    line-height: 80px;
    color: white;
  }
  
  .text-container .heading span{
    color: #da3349;
  }
  
  .text-container p {
    font-size: 1.5rem;
    line-height: 2rem;
    color: white;
  }
  
  .text-container, .image-container{
    flex: 1;
  }
  
  .text-container span{
    font-weight: bold;
  }

  .text-container p span{
    border-radius: 5px;
    color: white;
    padding: .3rem;
  }
  
  .image-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-container img{
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  
  .btn-group{
    display: flex;
    gap: 3rem;
    align-items: center;
  }
  
  .learn-more-btn{
    background-color: transparent;
    border: 2px solid #e09f3e;
    color: #e09f3e;
  }
  
  .learn-more-btn:hover{
    color: white;
  }

  .mb-cta{
    display: none;
  }

@media screen and (max-width:1380px) {
  .mb-cta{
    display: block;
  }

  .mb-cta a{
    color: white;
    text-decoration: none;
  }

  .exit-overlay{
    height: 100vh;
    width: 100%;
    background-color: rgb(43, 43, 43);
    z-index: 4;
    position: absolute;
    opacity: 0.8;
  }

  .hamburger-slide-in{
    transform: translateX(0);
  }

  .hamburger-slide-out{
    transform: translateX(-900px);
  }

  nav{
    margin-top: 0;
    justify-content: unset;
  }

  .logo-hero-mb{
    display: block;
    width: 50%;
    padding-top: 1rem;
    z-index: 2;
  }

    .nav-desktop{
        display: none;
      }
    
      .background-wrapper{
        position: relative;
      }
    
      .hamburger-menu{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
      }
    
      .hamburger-open{
        display: block;
        position: fixed;
        width: 100%;
        height: 100dvh;
      }
    
      .menu-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        cursor: pointer;
        position: absolute;
        margin: 2rem 0 0 1rem;
        padding: .2rem;
        z-index: 4;
        transition: all .5s;
        height: fit-content;
        width: fit-content;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }

      .hamburger-content{
        display: flex;
        flex-direction: column;
        position: fixed;
        border-radius: 0;
        height: 100%;
        width: 75%;
        background-color: rgb(54, 54, 54);
        z-index: 5;
        transition: all .5s;
      }

      .hamburger-content hr{
        appearance: none;
        border: none;
        outline: none;
        background-color: rgb(68, 68, 68);
        width: 90%;
        height: 3px;
        margin: 1rem 0;
      }

      .hamburger-content.open{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
      }

      .no-scroll{
        overflow: hidden;
      }

      .hamburger-content ul{
        position: relative;
      }

      .hamburger-content ul li{
        display: flex;
        gap: 1rem;
        align-items: center;
        font-size: 1.5rem;
      }

      .hamburger-content ul a{
        text-decoration: none;
      }

      .hero-page{
        position: relative;
        height: 100dvh;
        align-items: center;
      }

      .content{
        flex-direction: column-reverse;
        justify-content: space-around;
        gap: 0;
      }

      .text-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 90%;
        padding-inline: 0.75rem;
      }

      .text-container .heading{
        font-size: 2rem;
        line-height: 2.5rem;
      }

      .text-container p{
        font-size: 1rem;
      }

      .btn-group{
        gap: 1rem;
        flex-direction: column;
        justify-content: start;
        margin: 0 auto;
      }

      .btn-group a{
        width: 100%;
        font-size: 1rem;
      }

      .btn-group button{
        margin-top: 0;
        width: 100%;
        font-size: 1rem;
      }
}

@media screen and (max-width:1070px) {

      .btn-group{
        justify-content: center;
      }

      .text-container p {
        font-size: 1rem;
        line-height: 1.75rem;
        color: white;
      }
}

.scrolled{
  color: #e09f3e;
  background-color: #e9e9e9;
}

.not-scrolled{
  color: white;
  background-color: #e09f3e;
}